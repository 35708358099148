<template>
  <div class="container">
    <div class="columns">
      <div class="column"></div>
      <div class="column">
        <div data-test-id="login-card" class="card" style="margin-top: 30%">
          <div class="is-relative" style="top: -45px; left: 40%"><LogoRandomizer /></div>

          <div class="card-content">
            <form @submit.prevent>
              <FormField
                v-model:modelValue="loginForm.email.value"
                placeholder="Email"
                type="text"
                @blur="loginForm.email.validate()"
                :errors="loginForm.email.errors"
                @keyup.enter="onLogin"
              />
              <FormField
                v-model:modelValue="loginForm.password.value"
                placeholder="Password"
                type="password"
                @blur="loginForm.password.validate()"
                :errors="loginForm.password.errors"
                @keyup.enter="onLogin"
              />
            </form>
            <div class="block"></div>
            <div class="has-text-centered">
              <button
                @click="onLogin"
                :disabled="!loginForm.isValid"
                class="button is-success"
                :class="{ 'is-loading': isLoading && loginForm.isValid }"
                @keyup.enter="onLogin"
              >
                Login
              </button>
            </div>
          </div>
          <div class="card-footer" style="flex-wrap: wrap;">
            <p class="card-footer-item">
              <router-link :to="{ name: 'ForgotPasswordPage' }">Fogot Password? </router-link>
            </p>
            <p class="card-footer-item">
              <router-link :to="{ name: 'RegistrationPage' }">Register </router-link>
            </p>
            <p style="flex-basis: 100%; text-align: center; padding: 0.5rem; border-top: 1px solid #5d5d5d26; font-size: 0.8rem;">
              <a href="https://app3.yearly.report">Login to Yearly 3.0</a>
              
            </p>
          </div>
        </div>
      </div>
      <div class="column"></div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'

import { LoginForm } from '@/services/users/'

import firebaseFunctions from '@/composables/firebaseFunctions'
import yearlyStore from '@/composables/yearlyStore'

import FormField from '@/components/FormField'
import LogoRandomizer from '@/components/LogoRandomizer'
const FIELD_MAP = [
  {
    name: 'email',
    label: 'Email',
    placeHolder: 'Enter your email address',
    helpText: ''
  },
  {
    name: 'password',
    label: 'Password',
    placeHolder: 'password',
    helpText: ''
  }
]

export default {
  name: 'LoginForm',
  components: { FormField, LogoRandomizer },
  setup() {
    const router = useRouter()
    const { signIn } = firebaseFunctions()
    const { setUserAuth, refreshUser } = yearlyStore()
    const isLoading = ref(false)
    const loginForm = ref(new LoginForm())

    if(window.location.search.indexOf("p=true") === -1){
      window.location = "https://app3.yearly.report"
    }
    async function onLogin() {
      if (!loginForm.value.isValid) {
        return loginForm.value.validate()
      }
      try {
        isLoading.value = true
        const res = await signIn(loginForm.value.value)

        if (res) {
          const userAuth = res.user

          setUserAuth({
            accessToken: userAuth.accessToken,
            email: userAuth.email,
            firebaseId: userAuth.uid,
            refreshToken: userAuth.refreshToken
          })

          await refreshUser()

          router.push({ name: 'DashboardPage' })
        }
      } finally {
        isLoading.value = false
      }
      return
    }

    return {
      loginForm,
      fieldMap: FIELD_MAP,
      onLogin,
      isLoading
    }
  }
}
</script>

<style lang="scss" scoped></style>
