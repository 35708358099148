<template>
  <div style="padding: 0rem 4rem">
    <div class="block" style=""></div>
    <SubscriptionOptions
      :preSelectedPlan="{
        key: subscriptionCategoryFromRoute,
        frequency: subscriptionFrequencyFromRoute,
        tier: subscriptionTierFromRoute,
        style: 'newly'
      }"
      @subscription-updated="onRouteToHome"
      @newPlanSelected="onScrollToEl('#startButton')"
      layout="horizontal"
    >
      <template #subscription-save-btn="{ isDisabled, saveUpdates, newSelectedPlan }">
        <div>
          <button
            id="startButton"
            ref="startButton"
            :disabled="isDisabled"
            class="button is-primary is-centered"
            @click="onSubmitPlan(newSelectedPlan, saveUpdates)"
          >
            {{
              !isDisabled || !newSelectedPlan.category.value ? 'Select a plan' : 'Start new plan'
            }}
          </button>
          <a
            v-if="newSelectedPlan.category.value == 'free' || !newSelectedPlan.category.value"
            @click="onStartFree"
          >
            Continue to Try it Free</a
          >
        </div>
      </template>
    </SubscriptionOptions>
  </div>
</template>

<script>
import { ref, watchEffect, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import subscriptionFunctions from '@/composables/subscriptionFunctions'
import trackingFunctions from '@/composables/trackingFunctions'

import SubscriptionOptions from '@/views/Dashboard/__SubscriptionOptions'
export default {
  components: { SubscriptionOptions },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const $scrollTo = inject('$scrollTo')
    const startButton = ref(null)
    const {
      FREE,

      PLANS
    } = subscriptionFunctions()
    const { trackSignup, REGISTRATION_STEP_SUBSCRIPTION, COMPLETE_REGISTRATION } =
      trackingFunctions()

    const subscriptionCategoryFromRoute = route?.query?.plan || FREE
    const subscriptionFrequencyFromRoute = route?.query?.frequency || 'monthly'
    const subscriptionTierFromRoute = route?.query?.tier || 1

    async function onStartFree() {
      trackSignup(REGISTRATION_STEP_SUBSCRIPTION)
      trackSignup(COMPLETE_REGISTRATION)
      router.push({ name: 'HomePage' })
    }
    async function onSubmitPlan(plan, cb) {
      trackSignup(REGISTRATION_STEP_SUBSCRIPTION)
      trackSignup(COMPLETE_REGISTRATION)
      await cb(plan)
    }
    function onScrollToEl(el) {
      $scrollTo(el)
    }
    function onRouteToHome() {
      router.push({ name: 'HomePage' })
    }
    watchEffect(() => {
      if (startButton.value) {
        if (subscriptionCategoryFromRoute.value !== FREE && subscriptionCategoryFromRoute.value) {
          onScrollToEl('#startButton')
        }
      }
    })
    return {
      subscriptionCategoryFromRoute,
      subscriptionFrequencyFromRoute,
      subscriptionTierFromRoute,
      PLANS,
      onStartFree,
      startButton,
      onScrollToEl,
      onSubmitPlan,
      onRouteToHome
    }
  }
}
</script>

<style lang="scss" scoped></style>
