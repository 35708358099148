<template>
  <div class="container">
    <div class="columns">
      <div class="column"></div>
      <div class="column">
        <div class="card" style="margin-top: 30%">
          <div class="is-relative" style="top: -45px; left: 40%"><LogoRandomizer /></div>

          <div class="card-content">
            <span class="help is-clickable" @click="onLogout"
              >Sign in with a different account?</span
            >
            <form @submit.prevent>
              <div :key="i" v-for="(fieldOpts, i) in fieldMap">
                <FormField
                  :labelText="fieldOpts.label"
                  v-model:modelValue="userForm.field[fieldOpts.name].value"
                  :placeholder="fieldOpts?.placeHolder"
                  :helpText="fieldOpts?.helpText"
                  :errors="userForm.field[fieldOpts.name].errors"
                  :required="fieldOpts?.required"
                  v-if="fieldOpts.type != 'select'"
                />
                <FormField
                  v-else
                  :labelText="fieldOpts.label"
                  :placeholder="fieldOpts?.placeHolder"
                  :helpText="fieldOpts?.helpText"
                  :errors="userForm.field[fieldOpts.name].errors"
                  :required="fieldOpts?.required"
                >
                  <template #field-input>
                    <select
                      :disabled="fieldOpts?.readOnly"
                      class="input"
                      v-model="userForm.field[fieldOpts.name].value"
                    >
                      <option :key="i" :value="opt.value" v-for="(opt, i) in tierOpts">
                        {{ opt.label }}
                      </option>
                    </select>
                  </template></FormField
                >
              </div>
            </form>
            <div class="block"></div>
            <div class="has-text-centered">
              <button
                @click="onRegisterAccount"
                :disabled="!userForm.isValid"
                class="button is-success"
                :class="{ 'is-loading': isLoading && userForm.isValid }"
                @keyup.enter="onRegisterAccount"
              >
                Next: Confirm Plan
              </button>
            </div>
          </div>
          <div class="card-footer"></div>
        </div>
      </div>
      <div class="column"></div>
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue'
import { UserAccount, UserForm } from '@/services/users/'
import { useRoute, useRouter } from 'vue-router'

import firebaseFunctions from '@/composables/firebaseFunctions'
import commonModals from '@/composables/commonModals'
import yearlyStore from '@/composables/yearlyStore'

import subscriptionFunctions from '@/composables/subscriptionFunctions'
import trackingFunctions from '@/composables/trackingFunctions'

import FormField from '@/components/FormField'
import LogoRandomizer from '@/components/LogoRandomizer'

const FIELD_MAP = [
  {
    name: 'name',
    label: 'Name',
    placeHolder: '',
    required: true
  },
  {
    name: 'organization',
    label: 'Organization Name',
    placeHolder: '',
    required: true
  },
  {
    name: 'organizationSize',
    label: 'Organization Size',
    placeHolder: '',
    required: true,
    type: 'select'
  },
  {
    name: 'username',
    label: 'Username',
    placeHolder: '',
    required: true
  }
]

export default {
  name: 'AuthRegistrationPage',
  components: { FormField, LogoRandomizer },
  setup() {
    const Alert = inject('$alert')

    const { setUserAccount, user, logout } = yearlyStore()
    const { signOutUser } = firebaseFunctions()
    const isLoading = ref(false)
    const router = useRouter()
    const route = useRoute()
    const { showModal, plan, tier } = { ...route.query }
    const { trackSignup, REGISTRATION_STEP_ACCOUNT } = trackingFunctions()

    const userForm = ref(
      new UserForm({ email: user.value.email, organizationSize: tier ? tier : 1 })
    )
    const { TIER_OPTS, FREE } = subscriptionFunctions()
    const { showHelpModal } = commonModals()

    onMounted(() => {
      let tierFromRoute = tier || 1
      userForm.value.email.value = user.value.email
      userForm.value.organizationSize.value = tierFromRoute
    })

    async function onLogout() {
      try {
        await signOutUser()
        logout()

        router.replace({ name: 'LoginPage' })
      } catch (e) {
        console.log(e)
      }
    }

    async function onRegisterAccount() {
      if (!userForm.value.isValid) {
        return userForm.value.validate()
      }
      try {
        isLoading.value = true
        const res = await UserAccount.api.create({
          ...userForm.value.value,
          email: user.value.email,
          firebaseId: user.value.firebaseId
        })

        if (!res) return
        trackSignup(REGISTRATION_STEP_ACCOUNT)
        const userAccount = res

        setUserAccount(userAccount.toDict)

        if (plan && plan == FREE) {
          router.push({ name: 'HomePage' })
        } else {
          router.push({ name: 'SubscriptionRegistrationPage', query: route.query })
        }
      } catch (e) {
        const { response } = e
        if (response?.status >= 400 && response?.status < 500) {
          const { data } = response

          let message = '<h2>Error...</h2>'

          message = UserAccount.api.toMessageString(data.errors)
          Alert.alert({ message: message, type: 'error' })
        }
      } finally {
        isLoading.value = false
      }
      return
    }
    onMounted(async () => {
      if (showModal) {
        await showHelpModal(
          'Complete your account registration',
          '',
          'We just need a bit more information'
        )
      }
    })

    return {
      fieldMap: FIELD_MAP,
      userForm,
      isLoading,
      onRegisterAccount,
      onLogout,
      tierOpts: TIER_OPTS
    }
  }
}
</script>

<style lang="scss" scoped></style>
