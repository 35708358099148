<template>
  <div class="container">
    <div class="columns">
      <div class="column"></div>
      <div class="column">
        <div class="card" style="margin-top: 30%">
          <div class="is-relative" style="top: -45px; left: 40%"><LogoRandomizer /></div>

          <div class="card-content">
            <router-link :to="{ name: 'LoginPage' }">Back To Login</router-link>
            <form @submit.prevent>
              <FormField
                v-model:modelValue="resetPasswordForm.password.value"
                placeholder="Password"
                type="password"
                @blur="resetPasswordForm.password.validate()"
                :errors="resetPasswordForm.password.errors"
                @keyup.enter="onSendPasswordReset"
              />
              <FormField
                v-model:modelValue="resetPasswordForm.confirmPassword.value"
                placeholder="Confirm Password"
                type="password"
                @blur="resetPasswordForm.confirmPassword.validate()"
                :errors="resetPasswordForm.confirmPassword.errors"
                @keyup.enter="onSendPasswordReset"
              />

              <div class="block"></div>
              <div class="has-text-centered">
                <button
                  @click="onSendPasswordReset"
                  :disabled="!resetPasswordForm.isValid"
                  class="button is-success"
                  :class="{ 'is-loading': isLoading && !resetPasswordForm.isValid }"
                  @keypress.enter="onSendPasswordReset"
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="column"></div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue'
import { useRoute } from 'vue-router'

import { ResetPasswordForm } from '@/services/users'

import firebaseFunctions from '@/composables/firebaseFunctions'
import yearlyStore from '@/composables/yearlyStore'

import FormField from '@/components/FormField'
import LogoRandomizer from '@/components/LogoRandomizer'

export default {
  name: 'ForgotPassword',
  components: { FormField, LogoRandomizer },
  setup() {
    const Alert = inject('$alert')
    const { firebaseAuthErrorCodesInvalid, resetPassword, checkVerifCode } = firebaseFunctions()
    const route = useRoute()

    const { oobCode } = { ...route.query }

    const isLoading = ref(false)
    const resetPasswordForm = ref(new ResetPasswordForm())
    resetPasswordForm.value.oobCode.value = oobCode

    async function onSendPasswordReset() {
      if (!resetPasswordForm.value.isValid) {
        return resetPasswordForm.value.validate()
      }
      try {
        isLoading.value = true

        await checkVerifCode(resetPasswordForm.value.value.oobCode)
        const res = await resetPassword(
          resetPasswordForm.value.value.oobCode,
          resetPasswordForm.value.value.password
        )

        Alert.alert({
          message: 'Password changed!',
          type: 'success'
        })
      } catch (e) {
        const { message, code } = e
        console.log(code)
        if (firebaseAuthErrorCodesInvalid.includes(code)) {
          Alert.alert({
            message: message,
            type: 'error'
          })
        } else {
          Alert.alert({
            message: message ? message : 'An error occurred sending your reset email',
            type: 'error'
          })
        }
      } finally {
        isLoading.value = false
      }
    }

    return {
      resetPasswordForm,
      isLoading,
      onSendPasswordReset
    }
  }
}
</script>

<style lang="scss" scoped></style>
