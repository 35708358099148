<template>
  <div class="container">
    <div class="columns">
      <div class="column"></div>
      <div class="column">
        <div data-test-id="auth-register-card" class="card" style="margin-top: 30%">
          <div class="is-relative" style="top: -45px; left: 40%"><LogoRandomizer /></div>

          <div class="card-content">
            <form @submit.prevent>
              <p style="font-size: 2rem; margin: -3rem 0 2rem 0; font-weight: 600; line-height: 1; text-align: center;">
                Start you free trial.<br>No card required.
              </p>
              <FormField
                v-model:modelValue="authRegistrationForm.email.value"
                placeholder="Work Email"
                type="text"
                @blur="authRegistrationForm.email.validate()"
                :errors="authRegistrationForm.email.errors"
                @keyup.enter="onRegister"
                :required="true"
                labelText="Work Email"
                data-test-id="work-email-field"
              />
              <FormField
                v-model:modelValue="authRegistrationForm.password.value"
                placeholder="Password"
                type="password"
                @blur="authRegistrationForm.password.validate()"
                :errors="authRegistrationForm.password.errors"
                @keyup.enter="onRegister"
                :required="true"
                labelText="Password"
                data-test-id="password-field"
              />
              <FormField
                v-model:modelValue="authRegistrationForm.confirmPassword.value"
                placeholder="Password"
                type="password"
                @blur="authRegistrationForm.confirmPassword.validate()"
                :errors="authRegistrationForm.confirmPassword.errors"
                @keyup.enter="onRegister"
                :required="true"
                labelText="Confirm Password"
                data-test-id="confirm-password-field"
              />
            </form>
            <div class="block"></div>
            <div class="has-text-centered">
              <button
                @click="onRegister"
                :disabled="!authRegistrationForm.isValid"
                class="button is-success"
                :class="{ 'is-loading': isLoading && authRegistrationForm.isValid }"
                @keyup.enter="onRegister"
                data-test-id="submit-auth-registration-btn"
              >
                Register
              </button>
              
            </div>
          </div>
          <div class="card-footer"></div>
        </div>
      </div>
      <div class="column"></div>
    </div>
  </div>
</template>

<script>
window.location = "https://app3.yearly.report/signup"
// import { ref, inject } from 'vue'
// import { AuthRegistrationForm } from '@/services/users/'
// import { useRouter, useRoute } from 'vue-router'

// import firebaseFunctions from '@/composables/firebaseFunctions'
// import yearlyStore from '@/composables/yearlyStore'
// import trackingFunctions from '@/composables/trackingFunctions'

// import FormField from '@/components/FormField'
// import LogoRandomizer from '@/components/LogoRandomizer'

// export default {
//   name: 'AuthRegistrationPage',
//   components: { FormField, LogoRandomizer },
//   setup() {
//     const { createAuthForUser } = firebaseFunctions()
//     const { setUserAuth } = yearlyStore()

//     const isLoading = ref(false)
//     const router = useRouter()
//     const route = useRoute()
//     const { trackSignup, REGISTRATION_STEP_AUTH } = trackingFunctions()
//     const authRegistrationForm = ref(new AuthRegistrationForm())
//     async function onRegister() {
//       if (!authRegistrationForm.value.isValid) {
//         return authRegistrationForm.value.validate()
//       }
//       try {
//         isLoading.value = true
//         const res = await createAuthForUser(authRegistrationForm.value.value)
//         if (!res) return
//         trackSignup(REGISTRATION_STEP_AUTH)
//         const userAuth = res.user
//         setUserAuth({
//           accessToken: userAuth.accessToken,
//           email: userAuth.email,
//           firebaseId: userAuth.uid
//         })
//         router.replace({ name: 'AccountRegistrationPage', query: route.query })
//       } finally {
//         isLoading.value = false
//         window.location = "https://app3.yearly.report/signup"
//       }
//       return
//     }

//     return {
//       authRegistrationForm,
//       onRegister,
//       isLoading
//     }
//   }
// }
</script>

<style lang="scss" scoped></style>
