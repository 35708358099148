<template>
  <div class="container">
    <div class="columns">
      <div class="column"></div>
      <div class="column">
        <div class="card" style="margin-top: 30%">
          <div class="is-relative" style="top: -45px; left: 40%"><LogoRandomizer /></div>

          <div class="card-content">
            <FormField
              v-model:modelValue="emailField.value"
              placeholder="Email"
              type="text"
              @blur="emailField.validate()"
              :errors="emailField.errors"
              @keyup.enter="onSendPasswordReset"
            />

            <div class="block"></div>
            <div class="has-text-centered">
              <button
                @click="onSendPasswordReset"
                :disabled="!emailField.isValid"
                class="button is-success"
                :class="{ 'is-loading': isLoading && emailField.isValid }"
                @keypress.enter="onSendPasswordReset"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="column"></div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue'

import { EmailField } from '@/services/users'

import firebaseFunctions from '@/composables/firebaseFunctions'

import FormField from '@/components/FormField'
import LogoRandomizer from '@/components/LogoRandomizer'

export default {
  name: 'ForgotPassword',
  components: { FormField, LogoRandomizer },
  setup() {
    const Alert = inject('$alert')
    const { sendPasswordEmail, firebaseAuthErrorCodesInvalid } = firebaseFunctions()

    const isLoading = ref(false)
    const emailField = ref(EmailField)

    async function onSendPasswordReset() {
      if (!emailField.value.isValid) {
        return emailField.value.validate()
      }
      try {
        isLoading.value = true
        await sendPasswordEmail(emailField.value.value)

        Alert.alert({
          message: 'If an account with your email exists we will send reset instructions',
          type: 'success'
        })
      } catch (e) {
        const { message, code, name } = e
        if (firebaseAuthErrorCodesInvalid.includes(code)) {
          Alert.alert({
            message: 'If an account with your email exists we will send reset instructions',
            type: 'success'
          })
        } else {
          Alert.alert({
            message: message ? message : 'An error occurred sending your reset email',
            type: 'error'
          })
        }
      } finally {
        isLoading.value = false
      }
    }

    return {
      emailField,
      isLoading,
      onSendPasswordReset
    }
  }
}
</script>

<style lang="scss" scoped></style>
